import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

import HttpStorageI18n from "./services/storage/Language";

i18n
  .use(Backend)
// .use(LanguageDetector)
  .use(initReactI18next )
  .init({
    fallbackLng: 'ca',
    /* lng: HttpStorageI18n.getCurrentLanguage(), */
    lng: 'ca',
    backend: {
      loadPath: 'locales/{{lng}}/{{ns}}.json',
    },
    // have a common namespace used around the full app
    ns: ['translations', 'countries'],
    defaultNS: 'translations',
    fallbackNS: 'translations',
    debug: false,
    react: {
      wait: true,
      useSuspense: false
    }
  });


export default i18n;