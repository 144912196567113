// function isUserOfSameHospital(user: any, identity: any, context: any) { return true }

/**
 *  Reference:  d: delete, w: write/edit, c: create, r: read, l: list, h: hide
 */
export default  [
  { role: 'warehouse',    target: 'center.list',              permissions: 'r' },
  { role: 'warehouse',    target: 'center.detail',            permissions: 'r' },
  { role: 'warehouse',    target: 'center-group.list',        permissions: 'r' },
  { role: 'warehouse',    target: 'reference.list',           permissions: 'r' },
  { role: 'warehouse',    target: 'reference.detail',         permissions: 'r' },
  { role: 'warehouse',    target: 'model.list',               permissions: 'r' },
  { role: 'warehouse',    target: 'model.detail',             permissions: 'r' },
  { role: 'warehouse',    target: 'model.price',              permissions: 'h' },
  { role: 'warehouse',    target: 'supplier.list',            permissions: 'r' },
  { role: 'warehouse',    target: 'supplier.detail',          permissions: 'r' },
  { role: 'warehouse',    target: 'supplier.budget',          permissions: 'r' },
  { role: 'warehouse',    target: 'order.list',               permissions: 'r' },
  { role: 'warehouse',    target: 'request.list',             permissions: 'r' },
  { role: 'warehouse',    target: 'request.detail',           permissions: 'r' },
  { role: 'warehouse',    target: 'user.list',                permissions: 'r' },
  { role: 'warehouse',    target: 'campaign.list',            permissions: 'r' }, 
  { role: 'warehouse',    target: 'inventory.list',           permissions: 'r' },   
  { role: 'warehouse',    target: 'arrival.price',            permissions: 'h' },
  { role: 'warehouse',    target: 'delivery.price',           permissions: 'h' }
  // { role: 'center_user',    target: 'request',                   permissions: ['rl-wd', isUserOfSameHospital]}
]
