export default [
  'login',
  'home',
  'profile',
  'changePassword',
  'model',
  'reference',
  'center',
  'centergroup',
  'request',
  'delivery'
]