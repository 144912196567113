import AppPaths from '../../all/AppPaths'
import { pick } from 'lodash'
export default pick(
  AppPaths,
  'login',
  'home',
  'profile',
  'changePassword',
  'user',
  'model',
  'reference',
  'supplier',
  'center',
  'order',
  'centergroup',
  'campaign',
  'request',
  'stock',
  'arrivals',
  'delivery',
  'inventory',
  'agreement',
  'lot',
  'basat'
)