import AppPaths from '../../all/AppPaths'
import { pick } from 'lodash'
export default pick(
  AppPaths,
  'login',
  'home',
  'profile',
  'changePassword',
  'user',
  'model',
  'category',
  'reference',
  'center',
  'tax',
  'centergroup',
  'campaign',
  'request',
  'brand',
  'providerEntity',
  'delivery'
)