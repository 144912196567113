import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import moment from "moment";
import { withTranslation } from "react-i18next";

// App Routes
import Routes from "./Routes";

// Vendor dependencies
import "./Vendor";
// Application Styles
import "./styles/bootstrap.scss";
import "./styles/app.scss";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import StorageLanguage from "./services/storage/Language";
import { languagesList, languageDefault } from "./utils/Language";
import HttpLanguage from "./services/http/Language";

require("moment/locale/ca");
moment.locale("ca");
StorageLanguage.saveLanguage("ca");
// declare var PUBLIC_URL: string;

function App({ i18n }: any) {
	const basename = "/"; // process.env.NODE_ENV === 'development' ? '/' : (PUBLIC_URL || '/');
	return (
		<BrowserRouter basename={basename}>
			<Routes />
		</BrowserRouter>
	);
}

export default withTranslation()(App);
