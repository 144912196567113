export default [
  'login',
  'home',
  'profile',
  'changePassword',
  'user',
  'model',
  'reference',
  'supplier',
  'center',
  'order',
  'centergroup',
  'campaign',
  'request',
  'stock',
  'arrivals',
  'delivery',
  'inventory'
]