import { getPermissions, getPermissionsAsActions} from './rules'


export default class usePermissions {

  async getPermissions(user: any) {
    let response;
    if (user) {
      let permissions =  await getPermissions(user.role);
      if (permissions){
        response = await getPermissionsAsActions(permissions);
      }
    }    
    return response;
  }

}