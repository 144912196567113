export default {
  login: '/login',
  home: '/home',
  profile: '/profile',
  changePassword: '/change-password',
  user: {
    new: '/user/new',
    list: '/user/all',
    detail: '/user/:id'
  },
  model: {
    new: '/model/new',
    list: '/model/all',
    detail: '/model/:id'
  },
  category: {
    new: '/category/new',
    list: '/category/all',
    detail: '/category/:id'
  },
  reference: {
    new: '/reference/new',
    list: '/reference/all',
    detail: '/reference/:id'
  },
  supplier: {
    new: '/supplier/new',
    list: '/supplier/all',
    detail: '/supplier/:id'
  },
  center: {
    new: '/center/new',
    list: '/center/all',
    detail: '/center/:id'
  },
  order: {
    new: '/order/new',
    list: '/order/all',
    detail: '/order/:id'
  },
  budget: {
    new: '/budget/new',
    list: '/budget/all',
    detail: '/budget/:id'
  },
  budgetRequest: {
    new: '/budget-request/new',
    list: '/budget-request/all',
    detail: '/budget-request/:id'
  },
  tax: {
    new: '/tax/new',
    list: '/tax/all'
  },
  centergroup: {
    new: '/center-group/new',
    list: '/center-group/all'
  },
  campaign: {
    new: '/campaign/new',
    list: '/campaign/all'
  },
  request: {
    new: '/request/new',
    list: '/request/all',
    detail: '/request/:id'
  },
  brand: {
    new: '/brand/new',
    list: '/brand/all'
  },
  providerEntity: {
    new: '/provider-entity/new',
    list: '/provider-entity/all'
  },
  budgetExercise: {
    new: '/budget-exercise/new',
    list: '/budget-exercise/all'
  },
  comparator: {
    list: '/comparator'
  },
  stock: {
    list: '/stock/all'
  },
  arrivals: {
    list: '/arrivals/all',
    new: '/arrivals/new',
    detail: '/arrivals/:id'
  },
  expedientTypes: {
    list: '/expedient-type/all',
    new: '/expedient-type/new',
    detail: '/expedient-type/:id'
  },
  delivery: {
    list: '/delivery/all',
    new: '/delivery/new',
    detail: '/delivery/:id'
  },
  executionPlans: {
    list: '/execution-plan/all',
    new: '/execution-plan/new',
    detail: '/execution-plan/:id'
  },
  inventory: {
    list: '/inventory/all',
    new: '/inventory/new',
    detail: '/inventory/:id'
  },
  dashboard: {
    list: '/dashboard'
  },
  agreement: {
    list: '/agreement/all',
    detail: '/agreement/:id',
    new: '/agreement/new'
  },
  lot: {
    new: '/agreement/:id/lot/new',
    detail: '/agreement/:id/lot/:idLot'
  },
  basat: {
    list: '/basat/all',
    new: '/basat/new',
    detail: '/basat/:id'
  }
}
