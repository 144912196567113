import warehouseUserRules       from 'permissions/roles/warehouse/config/permissions'
import businessUserRules        from 'permissions/roles/business/config/permissions'
import centerUserRules          from 'permissions/roles/center_user/config/permissions'
import regionalUserRules        from 'permissions/roles/regional/config/permissions'
import viewerUserRules          from 'permissions/roles/viewer/config/permissions'
import adminWarehouseUserRules  from 'permissions/roles/admin_warehouse/config/permissions'
import centerDealAgreement     from 'permissions/roles/center_deal/config/permissions'

/**
 *  Reference:  d: delete, w: write/edit, c: create, r: read, l: list
 *  Example 1: { role: 'center_user', target: 'request.approvalStatus', permissions: 'r' }
 */
const rules: any = [
  ...centerUserRules,
  ...warehouseUserRules,
  ...businessUserRules,
  ...regionalUserRules,
  ...viewerUserRules,
  ...adminWarehouseUserRules,
  ...centerDealAgreement
]

/**
 * Returns rule given a target id
 */
export const getRuleForRole = async (target: string, role: string) => {
  return await rules.find((rule: any) => rule.role === role && rule.target === target)
}

 /**
 * Returns permission matrix for given user
 * Ex: for user `center_user` on target `request.approvalStatus` 
 * will return 'cwrdl'
 */
export const getPermissions = (user: any) => {
  return rules.filter((rule: any) => rule.role === user);
}

export const getPermissionsAsActions = (permissions: any) => {
  let objPermissions:any = {};
  for (let i = 0; i < permissions.length; i++) {
    const canCreate    = !permissions[i] || permissions[i].permissions.includes('c')
    const canEdit      = !permissions[i] || permissions[i].permissions.includes('w')
    const canRead      = !permissions[i] || permissions[i].permissions.includes('r')
    const canDelete    = !permissions[i] || permissions[i].permissions.includes('d')
    const canList      = !permissions[i] || permissions[i].permissions.includes('l')
    const canHide      = !permissions[i] || permissions[i].permissions.includes('h')
    const canOnlyRead  = canRead && !canEdit
    objPermissions[permissions[i].target] = { canCreate, canEdit, canRead, canDelete, canList, canOnlyRead, canHide };    
  }
  return objPermissions
}

export default rules