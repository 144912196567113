import React from "react";
import { useTranslation } from "react-i18next";
import "./Loaders.scss";

type customStyles = {
  height?: string;
  width?: string;
  customClass?: string;
};

export const LocalLoader: React.FC<customStyles> = ({
  height = "40px",
  width = "40px",
  customClass = "",
}) => (
  <div className={`local-loader ${customClass}`}>
    <svg
      className="spinner"
      width={width}
      height={height}
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className="path"
        fill="none"
        strokeWidth="6"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="30"
      ></circle>
    </svg>
  </div>
);

export const TextLoader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="local-loader text-loader">{t("common.loading")} ...</div>
  );
};

const PageLoader: React.FC<customStyles> = ({ customClass }) => (
  <div className={`page-loader ${customClass}`}>
    <LocalLoader />
  </div>
);

export const ButtonLoader: React.FC<customStyles> = ({ customClass }) => (
  <div className={`${customClass}`}>
    <i className="fa fa-spinner fa-spin ml-3 "></i>
  </div>
);

export const DashboardLoader: React.FC<customStyles> = ({
  height = "40px",
  width = "40px",
  customClass = "",
}) => (
  <div className={`page-loader ${customClass}`}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className="path"
        fill="none"
        strokeWidth="6"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="30"
      ></circle>
    </svg>
  </div>
);

export default PageLoader;
