import React from 'react'
import { useTranslation } from 'react-i18next'

const Footer = () => {
  const { t } = useTranslation()
  const year = new Date().getFullYear()

  return (
    <footer className='footer-container'>
      <span>&copy; {year} - {t('common.product-name')}</span>
    </footer>
  )
}

export default Footer
