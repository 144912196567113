import React, { Suspense, lazy } from 'react'
import { Switch, Route, Redirect, RouteProps } from 'react-router-dom'

import AuthInterceptor from 'services/AuthInterceptor'

import { Login } from 'Routes'

import PageLoader from 'components/Common/Loaders'
import Base from 'components/Layout/Base'

import StorageAuth from 'services/storage/Auth'

// Role Related
import AppPaths from './AppPaths'
import MetaMenu from './MetaMenu'
import ApiAuthPaths from './ApiAuthPaths'

const waitFor = (Tag: React.LazyExoticComponent<any>) => (props: any) => <Tag {...props} />

AuthInterceptor(ApiAuthPaths)

const Home = lazy(() => import('pages/Home/Home'))
const Users = lazy(() => import('pages/Users/Users'))
const References = lazy(() => import('pages/References/References'))
const ReferenceDetails = lazy(() => import('pages/ReferenceDetails/ReferenceDetails'))
const Suppliers = lazy(() => import('pages/Suppliers/Suppliers'))
const Centers = lazy(() => import('pages/Centers/Centers'))
const CenterDetails = lazy(() => import('pages/CenterDetails/CenterDetails'))
const SupplierDetails = lazy(() => import('pages/SupplierDetails/SupplierDetails'))
const UserProfile = lazy(() => import('pages/UserDetails/UserProfile/UserProfile'))
const ChangePassword = lazy(() => import('pages/UserDetails/ChangePassword/ChangePasword'))
const Models = lazy(() => import('pages/Models/Models'))
const ModelDetails = lazy(() => import('pages/ModelDetails/ModelDetails'))
const Orders = lazy(() => import('pages/Orders/Orders'))
const OrderDetails = lazy(() => import('pages/OrderDetails/OrderDetails'))
const CenterGroups = lazy(() => import('pages/CenterGroups/CenterGroups'))
const Campaigns = lazy(() => import('pages/Campaigns/Campaigns'))
const Requests = lazy(() => import('pages/Requests/Requests'))
const RequestDetails = lazy(() => import('pages/RequestDetails/RequestDetails'))
const Stock = lazy(() => import('pages/Stock/Stock'))
const StockArrivals = lazy(() => import('pages/StockArrivals/StockArrivals'))
const ArrivalsDetails = lazy(() => import('pages/Arrivals/ArrivalDetails'))
const Deliveries = lazy(() => import('pages/Deliveries/Deliveries'))
const DeliveryDetails = lazy(() => import('pages/Delivery/DeliveryDetails'))
const Inventories = lazy(() => import('pages/Inventories/Inventories'))
const Agreements = lazy(() => import("pages/Agreement/Agreement"));
const AgreementDetails = lazy(() => import("pages/AgreementDetail/AgreementDetails"));
const LotDetails = lazy(() => import("pages/LotDetail/LotDetails"));
const Basats = lazy(() => import("pages/Basats/Basats"));
const BasatDetail = lazy(() => import("pages/BasatDetail/BasatDetails"));

export default ({ location }: RouteProps) => {
  const isLoggedIn = StorageAuth.isTokenAlive(StorageAuth.getSession())

  if (!isLoggedIn) return <Login />

  return (
    <Base menu={MetaMenu}>
      <div>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            <Route path={AppPaths.home} component={waitFor(Home)} />
            <Route path={AppPaths.user.list} exact component={waitFor(Users)} />
            <Route path={AppPaths.supplier.list} exact component={waitFor(Suppliers)} />
            <Route path={AppPaths.supplier.detail} component={waitFor(SupplierDetails)} />
            <Route path={AppPaths.reference.list} exact component={waitFor(References)} />
            <Route path={AppPaths.reference.detail} component={waitFor(ReferenceDetails)} />
            <Route path={AppPaths.center.list} exact component={waitFor(Centers)} />
            <Route path={AppPaths.center.detail} component={waitFor(CenterDetails)} />
            <Route path={AppPaths.reference.list} exact component={waitFor(References)} />
            <Route path={AppPaths.reference.detail} component={waitFor(ReferenceDetails)} />
            <Route path={AppPaths.model.list} exact component={waitFor(Models)} />
            <Route path={AppPaths.model.detail} component={waitFor(ModelDetails)} />
            <Route path={AppPaths.order.list} exact component={waitFor(Orders)} />
            <Route path={AppPaths.order.new} exact component={waitFor(OrderDetails)} />
            <Route path={AppPaths.order.detail} component={waitFor(OrderDetails)} />
            <Route path={AppPaths.centergroup.list} exact component={waitFor(CenterGroups)} />
            <Route path={AppPaths.campaign.list} exact component={waitFor(Campaigns)} />
            <Route path={AppPaths.request.list} exact component={waitFor(Requests)} />
            <Route path={AppPaths.request.new} exact component={waitFor(RequestDetails)} />
            <Route path={AppPaths.request.detail} exact component={waitFor(RequestDetails)} />
            <Route path={AppPaths.stock.list} exact component={waitFor(Stock)} />
            <Route path={AppPaths.arrivals.new} exact component={waitFor(ArrivalsDetails)} />
            <Route path={AppPaths.arrivals.list} exact component={waitFor(StockArrivals)} />
            <Route path={AppPaths.arrivals.detail} exact component={waitFor(ArrivalsDetails)} />
            <Route path={AppPaths.delivery.new} exact component={waitFor(DeliveryDetails)} />
            <Route path={AppPaths.delivery.list} exact component={waitFor(Deliveries)} />
            <Route path={AppPaths.delivery.detail} exact component={waitFor(DeliveryDetails)} />
            <Route path={AppPaths.inventory.list} exact component={waitFor(Inventories)} />
            <Route path={AppPaths.agreement.list} exact component={waitFor(Agreements)} />
						<Route path={AppPaths.agreement.new} exact component={waitFor(AgreementDetails)} />
						<Route path={AppPaths.agreement.detail} exact component={waitFor(AgreementDetails)} />
						<Route path={AppPaths.lot.new} exact component={waitFor(LotDetails)} />
						<Route path={AppPaths.lot.detail} exact component={waitFor(LotDetails)} />
						<Route path={AppPaths.basat.list} exact component={waitFor(Basats)} />
						<Route path={AppPaths.basat.new} exact component={waitFor(BasatDetail)} />
						<Route path={AppPaths.basat.detail} exact component={waitFor(BasatDetail)} />

            <Route path="/profile" component={waitFor(UserProfile)} />
            <Route path="/change-password" component={waitFor(ChangePassword)} />
            <Redirect to={AppPaths.home} />
          </Switch>
        </Suspense>
      </div>
    </Base>
  )
}