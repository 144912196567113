/**
 *  Reference:  d: delete, w: write/edit, c: create, r: read, l: list, h: hide
 */
export default  [
  { role: 'admin_warehouse',    target: 'reference.list',                   permissions: 'r' },
  { role: 'admin_warehouse',    target: 'reference.detail',                 permissions: 'r' },
  { role: 'admin_warehouse',    target: 'request.approvalStatus',           permissions: 'r' },
  { role: 'admin_warehouse',    target: 'user.showByRole.warehouse',        permissions: 'r' },
  { role: 'admin_warehouse',    target: 'user.showByRole.admin_warehouse',  permissions: 'r' },
]
