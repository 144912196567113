import Axios, { AxiosRequestConfig } from 'axios';
import { handleError } from './utils'
import IUser from 'types/IUser';


let USER_CACHE: IUser | undefined = undefined; 

export default class User {

  private backendHost = process.env.REACT_APP_API_ENDPOINT;
  private prefix      = '/user';
  private requestConfig : Partial<AxiosRequestConfig> = {};

  constructor() {
    this.requestConfig.baseURL = `${this.backendHost}${this.prefix}`;
  }

  async changePassword(password: string, oldPassword: string) : Promise<any> {
    let result = {data: ''}

    try {
      result = await Axios.put(
        `/password`,
        {
          'newPassword' : password,
          'oldPassword' : oldPassword  
        }
        , this.requestConfig
      );
    } catch (err) {
      handleError(err)
    }
    return result.data
  }

  async getLoggedUser() : Promise<any> {
    let result = {data: undefined}

    try {
      if (!USER_CACHE) {
        result = await Axios.get(`/me`, this.requestConfig);
        USER_CACHE = result.data;
      }

      return USER_CACHE; 
    } catch (err) {
      handleError(err)
    }
    
    return result.data;
  }

  async getUser(id: string) : Promise<any> {
    let result = {data: ''}

    try {
      result = await Axios.get(`/${id}`, this.requestConfig);
    } catch (err) {
      handleError(err)
    }
    
    return result.data;
  }

  async updateLoggedUser(data: object) : Promise<any> {
    let result = {data: ''}

    try {
      result = await Axios.put('/me', data, this.requestConfig);
    } catch (err) {
      handleError(err)
    }    
    return result.data;
  }

  async updateUser(id:string, data: object) : Promise<any> {
    let result = {data: ''}

    try {
      result = await Axios.put(id, data, this.requestConfig);
    } catch (err) {
      handleError(err)
    }    
    return result.data;
  }

  async delete(id:string) : Promise<any> {
    let result = {data: ''}

    try {
      result = await Axios.delete(`/${id}`, this.requestConfig);
    } catch (err) {
      handleError(err)
    }
    
    return result.data;
  }

}