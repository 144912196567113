import MetaMenu from '../../all/MetaMenu'
let partial = JSON.parse(JSON.stringify(MetaMenu));
partial = partial
          .filter((item:any) => item.name !== 'Dades auxiliars')
          .map((item:any) => {
            if (item.name === "Presupostos"){
              item.submenu = item.submenu.filter((submenu:any) => submenu.name !== "Comparador")
            }
            return item;
          });
export default partial
