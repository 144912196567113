export default [
  'login',
  'home',
  'profile',
  'changePassword',
  'model',
  'reference',
  'center',
  'centergroup',
  'budgetExercise',
  'agreement',
  'basat',
  'lot',
]