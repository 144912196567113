import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect, RouteProps } from 'react-router-dom';

import AuthInterceptor from 'services/AuthInterceptor';

import { Login } from 'Routes';

import PageLoader from 'components/Common/Loaders';
import Base from 'components/Layout/Base';

import StorageAuth from 'services/storage/Auth';

// Role Related
import AppPaths from './AppPaths';
import MetaMenu from './MetaMenu';
import ApiAuthPaths from './ApiAuthPaths';

const waitFor = (Tag: React.LazyExoticComponent<any>) => (props: any) => <Tag {...props} />

AuthInterceptor(ApiAuthPaths);

const Home = lazy(() => import('pages/Home/Home'));
const References = lazy(() => import('pages/References/References'));
const ReferenceDetails = lazy(() => import('pages/ReferenceDetails/ReferenceDetails'));
const Centers = lazy(() => import('pages/Centers/Centers'));
const CenterDetails = lazy(() => import('pages/CenterDetails/CenterDetails'));
const UserProfile = lazy(() => import('pages/UserDetails/UserProfile/UserProfile'));
const ChangePassword = lazy(() => import('pages/UserDetails/ChangePassword/ChangePasword'));
const Models = lazy(() => import('pages/Models/Models'));
const ModelDetails = lazy(() => import('pages/ModelDetails/ModelDetails'));
const CenterGroups = lazy(() => import('pages/CenterGroups/CenterGroups'));
const Agreements = lazy(() => import('pages/Agreement/Agreement'));
const AgreementDetails = lazy(() => import('pages/AgreementDetail/AgreementDetails'));
const Basats = lazy(() => import('pages/Basats/Basats'));
const BasatDetail = lazy(() => import('pages/BasatDetail/BasatDetails'));
const LotDetails = lazy(() => import("pages/LotDetail/LotDetails"));

export default ({ location }: RouteProps) => {
  const isLoggedIn = StorageAuth.isTokenAlive(StorageAuth.getSession());

  if (!isLoggedIn) return <Login />;

  return (
    <Base menu={MetaMenu}>
      <div>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            <Route path={AppPaths.home} component={waitFor(Home)} />
            <Route path={AppPaths.reference.list} exact component={waitFor(References)} />
            <Route path={AppPaths.reference.detail} component={waitFor(ReferenceDetails)} />
            <Route path={AppPaths.center.list} exact component={waitFor(Centers)} />
            <Route path={AppPaths.center.detail} component={waitFor(CenterDetails)} />
            <Route path={AppPaths.model.list} exact component={waitFor(Models)} />
            <Route path={AppPaths.model.detail} component={waitFor(ModelDetails)} />
            <Route path={AppPaths.centergroup.list} exact component={waitFor(CenterGroups)} />
            <Route path={AppPaths.agreement.list} exact component={waitFor(Agreements)} />
            <Route path={AppPaths.agreement.detail} exact component={waitFor(AgreementDetails)} />
						<Route path={AppPaths.basat.list} exact component={waitFor(Basats)} />
						<Route path={AppPaths.basat.new} exact component={waitFor(BasatDetail)} />
						<Route path={AppPaths.lot.detail} exact component={waitFor(LotDetails)} />
						<Route path={AppPaths.basat.detail} exact component={waitFor(BasatDetail)} />
            <Route path="/profile" component={waitFor(UserProfile)} />
            <Route path="/change-password" component={waitFor(ChangePassword)} />
            <Redirect to={AppPaths.home} />
          </Switch>
        </Suspense>
      </div>
    </Base>
  );
}
