/**
 *  Reference:  d: delete, w: write/edit, c: create, r: read, l: list, h: hide
 */
export default  [
  { role: 'viewer',    target: 'center.list',              permissions: 'r' },
  { role: 'viewer',    target: 'center.detail',            permissions: 'r' },
  { role: 'viewer',    target: 'center-group.list',        permissions: 'r' },
  { role: 'viewer',    target: 'reference.list',           permissions: 'r' },
  { role: 'viewer',    target: 'reference.detail',         permissions: 'r' },
  { role: 'viewer',    target: 'reference.price',          permissions: 'h' },
  { role: 'viewer',    target: 'model.list',               permissions: 'r' },
  { role: 'viewer',    target: 'model.detail',             permissions: 'r' },
  { role: 'viewer',    target: 'model.price',              permissions: 'h' },
  { role: 'viewer',    target: 'supplier.list',            permissions: 'r' },
  { role: 'viewer',    target: 'supplier.detail',          permissions: 'r' },
  { role: 'viewer',    target: 'budget-request.list',      permissions: 'r' },
  { role: 'viewer',    target: 'budget-request.detail',    permissions: 'r' },
  { role: 'viewer',    target: 'budget.list',              permissions: 'r' },
  { role: 'viewer',    target: 'budget.detail',            permissions: 'r' },
  { role: 'viewer',    target: 'budget.price',             permissions: 'h' },
  { role: 'viewer',    target: 'order.list',               permissions: 'r' },
  { role: 'viewer',    target: 'order.detail',             permissions: 'r' },
  { role: 'viewer',    target: 'order.price',              permissions: 'h' },
  { role: 'viewer',    target: 'request.list',             permissions: 'r' },
  { role: 'viewer',    target: 'request.detail',           permissions: 'r' },
  { role: 'viewer',    target: 'request.send',             permissions: 'h' },
  { role: 'viewer',    target: 'user.list',                permissions: 'r' },
  { role: 'viewer',    target: 'user.detail',              permissions: 'r' },
  { role: 'viewer',    target: 'campaign.list',            permissions: 'r' },
  { role: 'viewer',    target: 'stock.send',               permissions: 'h' },
  { role: 'viewer',    target: 'arrival.list',             permissions: 'r' },
  { role: 'viewer',    target: 'arrival.detail',           permissions: 'r' },
  { role: 'viewer',    target: 'arrival.send',             permissions: 'h' },
  { role: 'viewer',    target: 'arrival.price',            permissions: 'h' },
  { role: 'viewer',    target: 'delivery.list',            permissions: 'r' },
  { role: 'viewer',    target: 'delivery.detail',          permissions: 'r' },
  { role: 'viewer',    target: 'delivery.price',           permissions: 'h' }  
]
