export default [
  '/user',
  '/category',
  '/reference',
  '/product-reference',
  '/campaign',
  '/supplier',
  '/order-status',
  '/admin/search',
  '/admin/config',
  '/asset',
  '/center',
  '/campaign',
  '/model',
  '/product-reference',
  '/brand',
  '/order',
  '/tax',
  '/order-status',
  '/request',
  '/request-status',
  '/request-approval-status',
  '/expedient-type',
  '/provider-entity',
  '/stock',
  '/arrival',
  '/warehouse',
  '/delivery',
  '/inventory',
  '/job'
]
