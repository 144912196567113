type TKeys = 'idToken' | 'refreshToken' | 'loginDuration' | 'loginAt' | 'rememberMe' | 'role' | 'id';
type TSession = {
  idToken       : string | null,
  refreshToken  : string | null,
  loginAt       : Date | null,
  loginDuration : number | null,
  rememberMe    : boolean | null,
  role          : string | null,
  id            : string | null,
};

export class Auth {
  
  public saveSession(idToken: string, refreshToken: string, loginDuration: number | undefined, rememberMe: boolean, role: string, id: string) {
    this.setItem('idToken', idToken);
    this.setItem('refreshToken', refreshToken);
    this.setItem('loginDuration', Number(loginDuration).toString());
    this.setItem('loginAt', new Date().toString());
    this.setItem('rememberMe', rememberMe.toString());
    this.setItem('role', role);
    this.setItem('id', id);
  }

  public removeSession() {
    this.removeItem('idToken');
    this.removeItem('refreshToken');
    this.removeItem('loginDuration');
    this.removeItem('loginAt');
    this.removeItem('rememberMe');
    this.removeItem('role');
    this.removeItem('id');
  }

  public getSession() : TSession {
    let loginDuration: any = this.getItem('loginDuration');
    if (loginDuration) {
      loginDuration = parseInt(loginDuration);
    }

    let loginAt: any = this.getItem('loginAt');
    if (loginAt) {
      loginAt = new Date(loginAt);
    }

    return {
      idToken       : this.getItem('idToken'),
      refreshToken  : this.getItem('refreshToken'),
      loginAt       : loginAt,
      loginDuration : loginDuration,
      rememberMe    : this.getItem('rememberMe') === 'true',
      role          : this.getItem('role'),
      id            : this.getItem('id'),
    };
  }

  public isTokenLapsed(session: TSession) {
    return !this.isTokenAlive(session);
  }

  public isTokenAlive(session: TSession) {
    if (
      !session.idToken
      || !session.refreshToken
      || !session.loginDuration
      || !session.loginAt
    ) {
      return false;
    }

    const now       = new Date();
    let lapseTime   = session.loginAt;

    if (session.loginAt && session.loginDuration && lapseTime) {
      lapseTime.setSeconds(lapseTime.getSeconds() + session.loginDuration)

      if (now < lapseTime || session.rememberMe) {
        return true;
      }
    }

    return false;
  }

  private setItem(key: TKeys, value: string) {
    localStorage.setItem(`auth.${key}`, value);
  }

  public getItem(key: TKeys) : string | null {
    return localStorage.getItem(`auth.${key}`);
  }

  private removeItem(key: TKeys) {
    localStorage.removeItem(`auth.${key}`);
  }

}

export default new Auth();
