import Axios from 'axios';

import StorageAuth from './../services/storage/Auth';
import Auth from './http/Auth';
import Notification from '../components/Elements/Notification';
import AppPaths from 'permissions/roles/all/AppPaths'

function isIncludedInPrivateAPIPaths(basePath: string | undefined, privateAPIPaths: any) {
  if (!basePath) {
    return false;
  }

  if (!process.env.REACT_APP_API_ENDPOINT) {
    console.log('API endpoint path must be defined');
    return false;
  }

  if (basePath.indexOf(process.env.REACT_APP_API_ENDPOINT) !== 0) {
    return false;
  }
  
  for (const tokenizedAPIPath of privateAPIPaths) {
    if (basePath.indexOf(tokenizedAPIPath) !== -1) {
      return true;
    }
  }

  return false;
}

export default ((privateAPIPaths: any) => {
  Axios.interceptors.request.use(async (config) => {
    if (isIncludedInPrivateAPIPaths(config.baseURL, privateAPIPaths)) {
      config.headers.Authorization = StorageAuth.getSession().idToken;
    }
  
    return config;
  });

  Axios.interceptors.response.use(undefined, async (error) => {
    if (
      error.config
      && error.response
      && error.response.status === 401
      && error.response.data
      && error.response.data.message === 'Token expired'
    ) {
      const Session = StorageAuth.getSession();
      if (!Session.rememberMe || !Session.refreshToken) {
        StorageAuth.removeSession();
        Notification.display('warning', `Session expired. Refresh page and login again.`);

        throw new Axios.Cancel();
      }

      let refreshedSession;
      try {
        refreshedSession = await new Auth().refreshToken(Session.refreshToken);
      } catch (err) {
        StorageAuth.removeSession();
        Notification.display('error', `No s'ha pogut actualitzar el testimoni. Torneu a iniciar la sessió.`);
        window.location.href = AppPaths.login
        throw new Axios.Cancel();
      }

      StorageAuth.saveSession(refreshedSession.idToken, refreshedSession.refreshToken, refreshedSession.expiresIn, Session.rememberMe, refreshedSession.role, refreshedSession.id);

      return Axios.request(error.config);
    }
  
    throw error;
  });
});
