
import routes from "permissions/roles/all/AppPaths"

export interface ISidebarMenuItem {
  heading?: string
  name?: string
  icon?: string //This value must match one of those icons from our provider https://iconify.design/icon-sets/simple-line-icons/
  translate?: string
  path?: string
  label?: {
    value: string | number
    color: string
  }
  submenu?: ISidebarMenu
}

export interface ISidebarMenu extends Array<ISidebarMenuItem> {}

const Menu: ISidebarMenu = [
  {
    name: 'Home',
    icon: 'icon-home',
    translate: 'sidebar.nav.home',
    path: routes.home,
  },
  {
    name: 'Centres',
    icon: 'icon-flag',
    translate: 'sidebar.nav.centers',
    submenu: [
      {
        name: 'Centres',
        path: routes.center.list,
        translate: 'sidebar.nav.centers',
      },
      {
        name: 'Regions sanitaries',
        path: routes.centergroup.list,
        translate: 'sidebar.nav.center-groups',
      }
    ]
  },
  {
    name: 'Catàleg',
    icon: 'icon-layers',
    path: '',
    translate: 'sidebar.nav.catalog',
    submenu: [
      {
        name: 'Referències',
        path: routes.reference.list,
        translate: 'sidebar.nav.references',
      },
      {
        name: 'Models',
        path: routes.model.list,
        translate: 'sidebar.nav.models',
      }
    ]
  },
  {
    name: 'Proveïdors',
    icon: 'icon-book-open',
    path: routes.supplier.list,
    translate: 'sidebar.nav.suppliers',
  },
  {
    name: 'Presupostos',
    icon: 'icon-calculator',
    path: routes.budget.list,
    translate: 'sidebar.nav.budgets',
    submenu: [
      {
        name: 'Sol·licituts',
        path: routes.budgetRequest.list,
        translate: 'sidebar.nav.budget-requests',
      },
      {
        name: 'Presupostos',
        path: routes.budget.list,
        translate: 'sidebar.nav.budgets',
      },
      {
        name: 'Comparador',
        path: routes.comparator.list,
        translate: 'sidebar.nav.comparator',
      }      
    ]    
  },
  {
    name: 'Comandes',
    icon: 'icon-basket',
    path: routes.order.list,
    translate: 'sidebar.nav.orders',
  },
  // {
  //   name: 'Contractacions',
  //   icon: 'icon-briefcase',
  //   path: '/contracts',
  //   translate: 'sidebar.nav.contracting',
  //   submenu: [
  //     {
  //       name: 'Expedients de compra',
  //       path: '/contracts/expedients',
  //       translate: 'sidebar.nav.records',
  //     },
  //     {
  //       name: 'Contractes',
  //       path: '/contracts/contracts',
  //       translate: 'sidebar.nav.contracts',
  //     },
  //   ]
  // },
  {
    name: 'Peticions',
    icon: 'icon-phone',
    path: routes.request.list,
    translate: 'sidebar.nav.requests',
  },
  {
    name: 'Usuaris',
    icon: 'icon-people',
    translate: 'sidebar.nav.users',
    path: routes.user.list,
  },
  {
    name: 'Plans d\'equipament',
    icon: 'icon-folder',
    translate: 'sidebar.nav.campaigns',
    path: routes.campaign.list,
  },
  {
    name: 'Almacenes',
    icon: 'icon-size-actual',
    translate: 'sidebar.nav.warehouse',
    submenu: [
      {
        name: 'Stock',
        path: routes.stock.list,
        translate: 'sidebar.nav.stock',
      },
      {
        name: 'Arribades',
        path: routes.arrivals.list,
        translate: 'sidebar.nav.arrivals',
      }
    ]
  },
  {
    name: 'Inventario',
    icon: 'icon-social-dropbox',
    translate: 'sidebar.nav.inventory',
    path: routes.inventory.list,
  },
  {
    name: 'agreement',
    icon: 'icon-tag',
    translate: 'sidebar.nav.agreement',
    path: routes.agreement.list,
    submenu: [
      {
        name: 'agreement',
        translate: 'sidebar.nav.agreement',
        path: routes.agreement.list,
      },
      {
        name: 'basat',
        translate: 'sidebar.nav.basats',
        path: routes.basat.list
      }
]
  },
  {
    name: 'Envíos',
    icon: 'icon-paper-plane',
    translate: 'sidebar.nav.deliveries',
    path: routes.delivery.list,
  },
  {
    name: 'Dades auxiliars',
    icon: 'icon-settings',
    path: '/aux-data',
    translate: 'sidebar.nav.aux-data',
    submenu: [
      {
        name: 'Impostos',
        path: routes.tax.list,
        translate: 'sidebar.nav.taxes',
      },
      {
        name: 'Marques',
        path: routes.brand.list,
        translate: 'sidebar.nav.brands',
      },
      {
        name: 'Families',
        path: routes.category.list,
        translate: 'sidebar.nav.categories',
      },      
      {
        name: 'Entitats proveïdoras',
        path: routes.providerEntity.list,
        translate: 'sidebar.nav.provider-entities',
      },
      {
        name: "Tipus d'expedients",
        path: routes.expedientTypes.list,
        translate: 'sidebar.nav.expedients',
      },
      {
        name: 'Exercicis pressupostaris',
        path: routes.budgetExercise.list,
        translate: 'sidebar.nav.budget-exercises'
      } , 
      {
        name: "Plans d'execució",
        path: routes.executionPlans.list,
        translate: 'sidebar.nav.execution-plans'
      }      
    ]
  },
];

export default Menu
