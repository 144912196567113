import React, { useState, useEffect } from 'react'
import { NotificationContainer } from '../Elements/Notification'
import Notification from 'components/Elements/Notification';
import HttpUser from '../../services/http/User';

import Header from './Header'
import Sidebar from './Sidebar'
import Footer from './Footer'

import UserProvider from "../../providers/userProvider";
import PermissionProvider from "../../providers/permissionProvider";
import usePermissions from 'permissions/lib/usePermissions'

const Base = ({ menu, children }: any) => {
  const [user, setUser] = useState({});
  const [permissionAccess, setPermissionAccess] = useState({})

  useEffect(()=>{
    handleUser();
  }, [])

  useEffect(()=>{
    if (user && Object.keys(user).length > 0){
      handlePermissions();
    }
  }, [user])

  const handleUser = async () =>{
    await new HttpUser().getLoggedUser()
          .then((response) => {
            setUser(response)
          })
          .catch((error) => {
              Notification.displayException(error);
          })
  }

  const handlePermissions = async () =>{
    await new usePermissions().getPermissions(user)
          .then((val) => {
            setPermissionAccess(val)
          })
          .catch((err) => console.log('err', err));
  }

  return (<div className="wrapper">
    <NotificationContainer />
    <Header />
    <UserProvider.Provider value={[user, setUser]}>
      <PermissionProvider.Provider value={[permissionAccess, setPermissionAccess]}>
        <Sidebar menu={menu} />

        {/* <Offsidebar /> */}

        <section className="section-container">
          {children}
        </section>
      </PermissionProvider.Provider>
    </UserProvider.Provider>

    <Footer />
  </div>)

}

export default Base
