// import { isUndefined } from 'lodash/isUndefined'
import Axios, { AxiosRequestConfig } from 'axios';
import { handleError } from './utils';


import { languageDefault } from "../../utils/Language";

type TLogin = {
  idToken       : string,
  refreshToken  : string,
  userLanguage ?: string,
  expiresIn     : number,
  role          : string,
  id            : string,
};

export default class Auth {

  private backendHost = process.env.REACT_APP_API_ENDPOINT;
  private prefix      = '/auth';

  private requestConfig : Partial<AxiosRequestConfig> = {};

  constructor() {
    this.requestConfig.baseURL = `${this.backendHost}${this.prefix}`;
  }

  async login(email: string, password: string) : Promise<TLogin> {
    let result = {
      data: {
        idToken: '', 
        refreshToken: '', 
        expiresIn: 0, 
        role: '', 
        id: '',
        userLanguage: ''
      }
    }

    try {
      result = await Axios.post(`/login`, { email, password }, this.requestConfig);
    } catch (err) {
      handleError(err)
    }
    const { idToken, refreshToken, expiresIn, role, id } = result.data;
    const userLanguage = result.data.userLanguage || languageDefault.acronym;
    
    return {
      idToken,
      refreshToken,
      expiresIn,
      userLanguage,
      role,
      id
    };
  }

  async refreshToken(refreshTokenString: string) : Promise<TLogin> {
    let result = {
      data: {
        idToken: '', 
        refreshToken: '', 
        expiresIn: 0, 
        role: '', 
        id: '',
        userLanguage: ''
      }
    }

    try {
      result = await Axios.post(`/token`, { refreshToken: refreshTokenString }, this.requestConfig);
    } catch (err) {
      handleError(err)
    }

    const { idToken, refreshToken, expiresIn, role, id } = result.data;
    
    return {
      idToken,
      refreshToken,
      expiresIn,
      role,
      id
    };
  }


  async resetPassword(email: string) {
    try {
      return await Axios.get(`/forgot-password/${ email }`, this.requestConfig);
    } catch (err) {
      handleError(err)
    }
  }

}