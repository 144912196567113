import { includes } from 'lodash'

import i18n from '../../i18n'

import StorageAuth from 'services/storage/Auth'

import Notification from '../../components/Elements/Notification'


export function handleError(err: any) {
  let errorMessage

  if (!err.response) {
    errorMessage = i18n.t('errors.unknown')
    throw new Error(errorMessage)
  }

  if(err.response.data && err.response.data.i18n) {
    errorMessage = err.response.data.i18n[i18n.language]
  } else {
    switch (err.response.status) {
      case 400:
      case 500:
      case 401:
      case 403:
      case 404:
        errorMessage = i18n.t(`errors.${err.response.status}`)
      default:
        errorMessage = i18n.t('errors.unknown')
    }
  }

  // Notification.display('error', errorMessage)

  throw new Error(errorMessage)
} 

export function createRelationsUpdateObject(currentRelations: any, nextRelations: any) {
  const relationsToAdd = nextRelations.reduce((acc: Array<any>, nextRelationId: string) => {
    if (!includes(currentRelations, nextRelationId)) {
      acc.push({ id: nextRelationId, action: 'add'})
    }

    return acc
  }, [])

  const relationsToDelete = currentRelations.reduce((acc: Array<any>, currentRelationId: string) => {
    if (!includes(nextRelations, currentRelationId)) {
      acc.push({ id: currentRelationId, action: 'delete'})
    }
    return acc
  }, [])


  return relationsToAdd.concat(relationsToDelete)
}

export const getRoleBasePath = (backendHost: any, prefix: any, customPath: boolean = false) => {
  const { role = '' }: any = StorageAuth.getSession()

  switch (role) {
    case 'center_user':  
    case 'center_deal':  
    case 'regional':
      if (customPath){
        return `${backendHost}/${role.replace('_', '-')}${prefix}`
      } else {
        return `${backendHost}${prefix}`;
      }
    case 'admin_warehouse':
      if (customPath && prefix === '/user') {
        return `${backendHost}/${role.replace('_', '-')}${prefix}`
      } else {
        return `${backendHost}${prefix}`;
      }    
    default:
      return `${backendHost}${prefix}`;
  }
}