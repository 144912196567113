import React, { Suspense, lazy } from 'react'
import { Switch, Route, Redirect, RouteProps } from 'react-router-dom'

import AuthInterceptor from 'services/AuthInterceptor'

import { Login } from 'Routes'

import PageLoader from 'components/Common/Loaders'
import Base from 'components/Layout/Base'

import StorageAuth from 'services/storage/Auth'

// Role Related
import AppPaths from './AppPaths'
import MetaMenu from './MetaMenu'
import ApiAuthPaths from './ApiAuthPaths'

const waitFor = (Tag: React.LazyExoticComponent<any>) => (props: any) => <Tag {...props}/>

AuthInterceptor(ApiAuthPaths)

const Home = lazy(() => import('pages/Home/Home'))
const Users = lazy(() => import('pages/Users/Users'))
const Categories = lazy(() => import('pages/Categories/Categories'))
const CategoryDetails = lazy(() => import('pages/CategoryDetails/CategoryDetails'))
const References = lazy(() => import('pages/References/References'))
const ReferenceDetails = lazy(() => import('pages/ReferenceDetails/ReferenceDetails'))
const Centers = lazy(() => import('pages/Centers/Centers'))
const CenterDetails = lazy(() => import('pages/CenterDetails/CenterDetails'))
const UserProfile = lazy(() => import('pages/UserDetails/UserProfile/UserProfile'))
const ChangePassword = lazy(() => import('pages/UserDetails/ChangePassword/ChangePasword'))
const Models = lazy(() => import('pages/Models/Models'))
const ModelDetails = lazy(() => import('pages/ModelDetails/ModelDetails'))
const Taxes = lazy(() => import('pages/Taxes/Taxes'))
const CenterGroups = lazy(() => import('pages/CenterGroups/CenterGroups'))
const Campaigns = lazy(() => import('pages/Campaigns/Campaigns'))
const Requests = lazy(() => import('pages/Requests/Requests'))
const RequestDetails = lazy(() => import('pages/RequestDetails/RequestDetails'))
const Brands = lazy(() => import('pages/Brands/Brands'))
const ProviderEntities = lazy(() => import('pages/ProviderEntities/ProviderEntities'))
const Deliveries = lazy(() => import('pages/Deliveries/Deliveries'))
const DeliveryDetails = lazy(() => import('pages/Delivery/DeliveryDetails'))

export default ({ location }: RouteProps) => {
  const isLoggedIn = StorageAuth.isTokenAlive(StorageAuth.getSession())
  
	if (!isLoggedIn) return <Login />

  return (
    <Base menu={MetaMenu}>
      <div>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            <Route path={ AppPaths.home } component={waitFor(Home)}/>
            <Route path={ AppPaths.user.list } exact component={waitFor(Users)}/>
            <Route path={ AppPaths.category.list } exact component={waitFor(Categories)}/>
            <Route path={ AppPaths.category.detail } component={waitFor(CategoryDetails)}/>
            <Route path={ AppPaths.reference.list } exact component={waitFor(References)}/>
            <Route path={ AppPaths.reference.detail } component={waitFor(ReferenceDetails)}/>
            <Route path={ AppPaths.center.list } exact component={waitFor(Centers)}/>
            <Route path={ AppPaths.center.detail } component={waitFor(CenterDetails)}/>
            <Route path={ AppPaths.reference.list } exact component={waitFor(References)}/>
            <Route path={ AppPaths.reference.detail } component={waitFor(ReferenceDetails)}/>	
            <Route path={ AppPaths.model.list } exact component={waitFor(Models)}/>
            <Route path={ AppPaths.model.detail } component={waitFor(ModelDetails)}/>
            <Route path={ AppPaths.tax.list } exact component={waitFor(Taxes)}/>
            <Route path={ AppPaths.centergroup.list } exact component={waitFor(CenterGroups)}/>
            <Route path={ AppPaths.campaign.list } exact component={waitFor(Campaigns)}/>
            <Route path={ AppPaths.request.list } exact component={waitFor(Requests)}/>
            <Route path={ AppPaths.request.new } exact component={waitFor(RequestDetails)}/>
            <Route path={ AppPaths.request.detail } exact component={waitFor(RequestDetails)}/>
            <Route path={ AppPaths.brand.list } exact component={waitFor(Brands)}/>
            <Route path={ AppPaths.providerEntity.list } exact component={waitFor(ProviderEntities)}/>
            <Route path={ AppPaths.delivery.new } exact component={waitFor(DeliveryDetails)}/>
            <Route path={ AppPaths.delivery.list } exact component={waitFor(Deliveries)}/>
            <Route path={ AppPaths.delivery.detail } exact component={waitFor(DeliveryDetails)}/>
            <Route path="/profile" component={waitFor(UserProfile)}/>
            <Route path="/change-password" component={waitFor(ChangePassword)}/>
            <Redirect to={ AppPaths.home }/>
          </Switch>
        </Suspense>
      </div>
    </Base>
  )
}