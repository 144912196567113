import React, { Component, useState } from 'react'
import { withRouter } from 'react-router-dom'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody
} from 'reactstrap'

import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { toggleSetting } from '../../store/actions/actions'
import { Auth } from '../../services/storage/Auth'
import { Language } from '../../services/storage/Language'
import AppPaths from 'permissions/roles/all/AppPaths'
import { Trans, useTranslation, withTranslation, WithTranslation } from 'react-i18next'
import HttpStorageI18n from '../../services/storage/Language'
import { RouteComponentProps } from 'react-router'
import { Tooltip } from 'reactstrap'
import moment from 'moment'

import '../../styles/app/layout/header.scss'

interface HeaderProps extends WithTranslation, RouteComponentProps {
  toggleSetting: typeof toggleSetting,
}

class Header extends Component<HeaderProps> {
  state = {
    navSearchOpen: false,
    toolTipOpen: false,
  }

  buttonToolTip: React.RefObject<any>;

  constructor(props: any) {
    super(props)
    this.state = {
      navSearchOpen: false,
      toolTipOpen: false
    }
    this.buttonToolTip = React.createRef();
  }

  changeLanguage = (lng: string) => {
    HttpStorageI18n.saveLanguage(lng)
    this.props.i18n.changeLanguage(lng)
    require(`moment/locale/${lng}`)
    moment.locale(lng)
  }

  toggleOffsidebar = (e: React.MouseEvent) => {
    e.preventDefault()
    this.props.toggleSetting('offsidebarOpen')
  }

  toggleCollapsed = (e: React.MouseEvent) => {
    e.preventDefault()
    this.props.toggleSetting('isCollapsed')
    this.resize()
  }

  toggleAside = (e: React.MouseEvent) => {
    e.preventDefault()
    this.props.toggleSetting('asideToggled')
  }

  resize() {
    const event = document.createEvent('HTMLEvents')
    event.initEvent('resize', true, false)
    document.dispatchEvent(event)
  }

  private logOut = () => {
    new Auth().removeSession()
    new Language().removeLanguageBackend()
    window.location.href = AppPaths.login

    return
  }

  handleTooltip = () => {
    this.setState({ toolTipOpen: !this.state.toolTipOpen })
  }

  render = () => {

    return (
      <div id='header'>
        <header className='topnavbar-wrapper'>
          {/* START Top Navbar */}
          <nav className='navbar topnavbar'>
            {/* START navbar header */}
            <div className='navbar-header'>
              <a className='navbar-brand' href='#/'>
                <div className='brand-logo'>
                  <img
                    className='img-fluid'
                    style={{ maxWidth: '120px' }}
                    src='../img/logo-wide.png'
                    alt='logo-header'
                  />
                </div>
                <div className='brand-logo-collapsed'>
                  <img
                    className='img-fluid'
                    style={{ maxHeight: '40px' }}
                    src='../img/logo-collapsed.png'
                    alt='logo-header'
                  />
                </div>
              </a>
            </div>
            {/* END navbar header */}

            {/* START Left navbar */}
            <ul className='navbar-nav mr-auto flex-row'>
              <li className='nav-item'>
                {/* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
                <a
                  href=''
                  className='nav-link d-none d-md-block d-lg-block d-xl-block'
                  onClick={this.toggleCollapsed}
                >
                  <em className='fas fa-bars'></em>
                </a>
                {/* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
                <a
                  href=''
                  className='nav-link sidebar-toggle d-md-none'
                  onClick={this.toggleAside}
                >
                  <em className='fas fa-bars'></em>
                </a>
              </li>
            </ul>
            {/* END Left navbar */}
            {/* START Right Navbar */}

            <ul className='navbar-nav flex-row' style={{ paddingTop: '.4rem' }}>
              {process.env.REACT_APP_PRODUCT_NAME !== 'CatSalut' &&
                <li className='nav-item'>
                  <UncontrolledDropdown>
                    <DropdownToggle tag='a' className='nav-link planet' caret>
                      <span>
                        <Trans
                          i18nKey={`common.languages-acronym.${this.props.i18n.language}`}
                        />
                      </span>
                    </DropdownToggle>
                    <DropdownMenu right className='dropdown-menu-right'>
                      <DropdownItem onClick={() => this.changeLanguage('ca')}>
                        <Trans i18nKey={'common.languages-acronym.ca'} />
                      </DropdownItem>
                      <DropdownItem onClick={() => this.changeLanguage('es')}>
                        <Trans i18nKey={'common.languages-acronym.es'} />
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
              }
              <li className='nav-item' >
                <div style={{ padding: '0 15px', cursor: 'pointer', fontSize: '1.2em' }}>
                  <i className='icon-question icon-header' ref={this.buttonToolTip} onClick={this.handleTooltip} />
                  <Tooltip placement='bottom' isOpen={this.state.toolTipOpen} autohide={false} target={this.buttonToolTip.current} toggle={this.handleTooltip}>
                    <p><Trans i18nKey='dashboard.INCIDENT-DESCRIPTION1'></Trans></p>
                    <p className='text-container text-white text-decoration-none'><a className='text-white text-decoration-none text-toolip' href="mailto:support-panacea@wheelhub.atlassian.net">support-panacea@wheelhub.atlassian.net</a></p>
                  </Tooltip>
                </div>
              </li>
              <li className='nav-item'>
                <UncontrolledDropdown>
                  <DropdownToggle tag='a' className='nav-link user' caret>
                    <em className='icon-user'></em>
                  </DropdownToggle>
                  <DropdownMenu right className='dropdown-menu-right'>
                    <DropdownItem
                      onClick={() =>
                        this.props.history.push(`${AppPaths.profile}`)
                      }
                    >
                      <Trans i18nKey='header.userProfile'></Trans>
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        this.props.history.push(
                          `${AppPaths.changePassword}`
                        )
                      }
                    >
                      <Trans i18nKey='header.change-password'></Trans>
                    </DropdownItem>
                    <DropdownItem onClick={this.logOut}>
                      <Trans i18nKey='header.logout'></Trans>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
            {/* END Right Navbar */}
          </nav>
          {/* END Top Navbar */}
        </header>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleSetting: bindActionCreators(toggleSetting, dispatch)
})

export default connect(
  null,
  mapDispatchToProps
)(withTranslation('translations')(withRouter(Header)))
