// function isUserOfSameHospital(user: any, identity: any, context: any) { return true }

/**
 *  Reference:  d: delete, w: write/edit, c: create, r: read, l: list, h: hide
 */
export default  [
  { role: 'center_user',    target: 'center.list',              permissions: 'r' },
  { role: 'center_user',    target: 'center.detail',            permissions: 'r' },
  { role: 'center_user',    target: 'center-group.list',        permissions: 'r' },
  { role: 'center_user',    target: 'reference.list',           permissions: 'r' },
  { role: 'center_user',    target: 'reference.detail',         permissions: 'r' },
  { role: 'center_user',    target: 'model.list',               permissions: 'r' },
  { role: 'center_user',    target: 'model.detail',             permissions: 'r' },
  { role: 'center_user',    target: 'campaign.list',            permissions: 'r' },
  { role: 'center_user',    target: 'delivery.list',            permissions: 'r' },
  { role: 'center_user',    target: 'delivery.detail',          permissions: 'r' },
  { role: 'center_user',    target: 'request.send',             permissions: 'h' },
  { role: 'center_user',    target: 'request.stock',            permissions: 'h' },
  { role: 'center_user',    target: 'request.addProducts',      permissions: 'h' },
  { role: 'center_user',    target: 'request.deleteProductEdit',permissions: 'h' },
  { role: 'center_user',    target: 'request.approvalStatus',   permissions: 'r' },
  { role: 'center_user',    target: 'request.statusType',       permissions: 'r' },
  { role: 'center_user',    target: 'reference.price',          permissions: 'h' },
  { role: 'center_user',    target: 'model.price',              permissions: 'h' },
  { role: 'center_user',    target: 'budget.price',             permissions: 'h' },
  { role: 'center_user',    target: 'order.price',              permissions: 'h' },
  { role: 'center_user',    target: 'arrival.price',            permissions: 'h' },
  { role: 'center_user',    target: 'delivery.price',           permissions: 'h' }  

  // { role: 'center_user',    target: 'request',                   permissions: ['rl-wd', isUserOfSameHospital]}
]
