export default [
  'login',
  'home',
  'profile',
  'changePassword',
  'user',
  'model',
  'category',
  'reference',
  'center',
  'tax',
  'centergroup',
  'campaign',
  'request',
  'brand',
  'providerEntity',
  'delivery'
]