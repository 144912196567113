/**
 *  Reference:  d: delete, w: write/edit, c: create, r: read, l: list
 */
export default  [
  { role: 'regional',    target: 'reference.list',                    permissions: 'r' },
  { role: 'regional',    target: 'reference.detail',                  permissions: 'r' },
  { role: 'regional',    target: 'center-group.list',                 permissions: 'r' },
  { role: 'regional',    target: 'model.list',                        permissions: 'r' },
  { role: 'regional',    target: 'model.detail',                      permissions: 'r' },
  { role: 'regional',    target: 'model.price',                       permissions: 'h' },
  { role: 'regional',    target: 'delivery.list',                     permissions: 'r' },
  { role: 'regional',    target: 'delivery.detail',                   permissions: 'r' },
  { role: 'regional',    target: 'user.center-group',                 permissions: 'h' },
  { role: 'regional',    target: 'user.role',                         permissions: 'h' },
  { role: 'regional',    target: 'request.statusType',                permissions: 'r' },
  { role: 'regional',    target: 'request.regionalApprovalStatus',    permissions: 'r' },
  { role: 'regional',    target: 'request.addProducts',               permissions: 'h' },
  { role: 'regional',    target: 'request.deleteProductEdit',         permissions: 'h' },
  { role: 'regional',    target: 'campaign.list',                     permissions: 'r' },
]
