import _ from 'lodash'
import MetaMenu from '../../all/MetaMenu'
export default _.cloneDeep(MetaMenu).filter((item:any) => item.name !== 'Proveïdors' &&
                                            item.name !== 'Presupostos' && 
                                            item.name !== 'Comandes' && 
                                            item.name !== 'Usuaris' && 
                                            item.name !== 'Peticions' && 
                                            item.name !== 'Envíos' && 
                                            item.name !== 'Plans d\'equipament' && 
                                            item.name !== 'Almacenes' &&
                                            item.name !== 'Dades auxiliars')